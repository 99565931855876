import {DDown_utilAlliance,DDown_utilOperator,DDown_utilService,DDown_utilTrade,DDown_utilVessel} from '../commonDataSource';
import {processCriteriaFormItems} from '../helper';

export function getFormItems(mode, objForm) 
{

    //  dataType: for FilterBuilder only
    //  editorTemplate: for FilterBuilder only when custom editor is used
    // caption: for FilterBuilder only
    // defaultCriteria: for form criteria only if default criteria will be set
    // searchOperator: for form criteria only if custom operator is used
    return processCriteriaFormItems([
        
        {                    
            dataField: 'tradeGroupSeq',
            editorType: 'dxSelectBox',
            dataType: 'number',
            /*validationRules:[{type:"required", message:"It is required"}],*/
            editorOptions: {
                dataSource: objForm.dsUtilTrade,
                displayExpr: 'trade',
                valueExpr:'tradeGroupSeq',
                searchEnabled:'true',
                searchMode:'contains',
                searchExpr:'trade',
                searchTimeout:'200',
                minSearchLength:'2',
                showDataBeforeSearch:'true',
                showClearButton:'true',
                onValueChanged:objForm.tradeValueChanged
            },
            defaultCriteria: 3,
            searchOperator: '=',
            label: {text: 'Trade'},
            colSpan: 1,
            visible: true
        },
        {                    
            dataField: 'service',
            editorType: 'dxTagBox',
            dataType: 'string',
            /*validationRules:[{type:"required", message:"It is required"}],*/
            editorOptions: {
                dataSource: objForm.dsUtilService,
                displayExpr: 'serviceName',
                valueExpr:'service',
                searchEnabled:'true',
                searchMode:'contains',
                searchExpr:'serviceName',
                searchTimeout:'200',
                minSearchLength:'2',
                showDataBeforeSearch:'true',
                showClearButton:'true',
                showSelectionControls:'true',
                onValueChanged:objForm.serviceValueChanged,
                showDropDownButton: 'true',
                selectAllMode: 'page'
            },
            searchOperator: 'in',
            label: {text: 'Service'},
            colSpan: 2,
            visible: true
        },
        {                    
            dataField: 'alliance',
            editorType: 'dxTagBox',
            dataType: 'string',
            /*validationRules:[{type:"required", message:"It is required"}],*/
            editorOptions: {
                dataSource: objForm.dsUtilAlliance,
                displayExpr: 'alliance',
                valueExpr:'alliance',
                searchEnabled:'true',
                searchMode:'contains',
                searchExpr:'alliance',
                searchTimeout:'200',
                minSearchLength:'2',
                showDataBeforeSearch:'true',
                showClearButton:'true',
                showSelectionControls:'true',
                onValueChanged:objForm.allianceValueChanged,
                showDropDownButton: 'true',
                selectAllMode: 'page'

            },
            searchOperator: 'in',
            label: {text: 'Alliance'},
            colSpan: 1,
            visible: true
        },
        {                    
            dataField: 'operator',
            editorType: 'dxTagBox',
            dataType: 'string',
            /*validationRules:[{type:"required", message:"It is required"}],*/
            editorOptions: {
                dataSource: objForm.dsUtilOperator,
                displayExpr: 'operator',
                valueExpr:'operator',
                searchEnabled:'true',
                searchMode:'contains',
                searchExpr:'operator',
                searchTimeout:'200',
                minSearchLength:'2',
                showDataBeforeSearch:'true',
                showClearButton:'true',
                showSelectionControls:'true',
                onValueChanged:objForm.operatorValueChanged,
                showDropDownButton: 'true',
                selectAllMode: 'page'
            },
            searchOperator: 'in',
            label: {text: 'Operator'},
            colSpan: 1,
            visible: true
        },
        {                    
            dataField: 'vessel',
            editorType: 'dxTagBox',
            dataType: 'string',
            /*validationRules:[{type:"required", message:"It is required"}],*/
            editorOptions: {
                dataSource: objForm.dsUtilVessel,
                displayExpr: 'vessel',
                valueExpr:'vessel',
                searchEnabled:'true',
                searchMode:'contains',
                searchExpr:'vessel',
                searchTimeout:'200',
                minSearchLength:'2',
                showDataBeforeSearch:'true',
                showClearButton:'true',
                showSelectionControls:'true',
                onValueChanged:objForm.vesselValueChanged,
                showDropDownButton: 'true',
                selectAllMode: 'page'
            },
            searchOperator: 'in',
            label: {text: 'Vessel'},
            colSpan: 1,
            visible: true
        }
       
        ], mode);
}