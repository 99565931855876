import React from 'react';
import utilizationChart from './utilizationChart';
import {getFormItems} from './formItem';
import EnquiryBase from '../enquiryBase';
import {DDown_utilAlliance,DDown_utilOperator,DDown_utilService,DDown_utilTrade,DDown_utilVessel} from '../commonDataSource';

class Utilization extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        critTrade: 3,
      };

      this.tradeValueChanged = this.tradeValueChanged.bind(this);
      this.serviceValueChanged = this.serviceValueChanged.bind(this);
      this.allianceValueChanged = this.allianceValueChanged.bind(this);
      this.operatorValueChanged = this.operatorValueChanged.bind(this);
      this.vesselValueChanged = this.vesselValueChanged.bind(this);

      this.dsUtilService = DDown_utilService();
      this.dsUtilTrade = DDown_utilTrade();
      this.dsUtilAlliance = DDown_utilAlliance();
      this.dsUtilOperator = DDown_utilOperator();
      this.dsUtilVessel = DDown_utilVessel();
      this.formItemsSimple = getFormItems('simple', this);
      this.formItemsBasic = getFormItems('basic', this);
      this.formItemsAdvance = getFormItems('advance', this);

      

    }

    setDSFilter(field) {
      var filter = [];

      if (field !== 'trade' && this.state.critTrade) {
        if (filter.length > 0)
          filter = [...'and'];
        filter = [...[['tradeGroupSeq','=',this.state.critTrade]]];
      }

      if (field !== 'service' && this.state.critService && this.state.critService.length > 0) {
        if (filter.length > 0)
          filter = [...filter,'and'];
        var serviceFilter = [];
        this.state.critService.forEach(element => {
          if (serviceFilter.length > 0)
            serviceFilter = [...serviceFilter, 'or'];
          serviceFilter = [...serviceFilter, [['service','=',element]]];
        });
        if (serviceFilter.length > 0)
          filter = [...filter, serviceFilter];
      }
      if (field !== 'alliance' && this.state.critAlliance && this.state.critAlliance.length > 0) {
        if (filter.length > 0)
          filter = [...filter,'and'];
        var allianceFilter = [];
        this.state.critAlliance.forEach(element => {
          if (allianceFilter.length > 0)
            allianceFilter = [...allianceFilter, 'or'];
          allianceFilter = [...allianceFilter, [['alliance','=',element]]];
        });
        if (allianceFilter.length > 0)
          filter = [...filter, allianceFilter];
      }
      if (field !== 'operator' && this.state.critOperator && this.state.critOperator.length > 0) {
        if (filter.length > 0)
          filter = [...filter,'and'];
        var operatorFilter = [];
        this.state.critOperator.forEach(element => {
          if (operatorFilter.length > 0)
            operatorFilter = [...operatorFilter, 'or'];
          operatorFilter = [...operatorFilter, [['operator','=',element]]];
        });
        if (operatorFilter.length > 0)
          filter = [...filter, operatorFilter];
      }
      if (field !== 'vessel' && this.state.critVessel && this.state.critVessel.length > 0) {
        if (filter.length > 0)
          filter = [...filter,'and'];
        var vesselFilter = [];
        this.state.critVessel.forEach(element => {
          if (vesselFilter.length > 0)
            vesselFilter = [...vesselFilter, 'or'];
          vesselFilter = [...vesselFilter, [['vessel','=',element]]];
        });
        if (vesselFilter.length > 0)
          filter = [...filter, vesselFilter];
      }
      if (filter.length <= 0) filter = null;

      
      if (field === 'trade') {
        this.dsUtilTrade.filter(filter);
        if (this.dsUtilTrade.isLoaded() || this.dsUtilTrade.isLoading())
          this.dsUtilTrade.reload();
      } else if (field === 'service') {
        this.dsUtilService.filter(filter);
        if (this.dsUtilService.isLoaded() || this.dsUtilService.isLoading())
          this.dsUtilService.reload();
      } else if (field === 'alliance') {
        this.dsUtilAlliance.filter(filter);
        if (this.dsUtilAlliance.isLoaded() || this.dsUtilAlliance.isLoading())
          this.dsUtilAlliance.reload();
      } else if (field === 'operator') {
        this.dsUtilOperator.filter(filter);
        if (this.dsUtilOperator.isLoaded() || this.dsUtilOperator.isLoading())
          this.dsUtilOperator.reload();
      } else if (field === 'vessel') {
        this.dsUtilVessel.filter(filter);
        if (this.dsUtilVessel.isLoaded() || this.dsUtilVessel.isLoading())
          this.dsUtilVessel.reload();
      }
    }


    serviceValueChanged(e) {
      this.setState({'critService': e.value});
      this.setDSFilter('trade');
      this.setDSFilter('alliance');
      this.setDSFilter('operator');
      this.setDSFilter('vessel');

    }
    allianceValueChanged(e) {
      this.setState({'critAlliance': e.value});
      this.setDSFilter('trade');
      this.setDSFilter('service');
      this.setDSFilter('operator');
      this.setDSFilter('vessel');
    }
    operatorValueChanged(e) {
      this.setState({'critOperator': e.value});
      this.setDSFilter('trade');
      this.setDSFilter('service');
      this.setDSFilter('alliance');
      this.setDSFilter('vessel');

    }
    vesselValueChanged(e) {
      this.setState({'critVessel': e.value});
      this.setDSFilter('trade');
      this.setDSFilter('service');
      this.setDSFilter('alliance');
      this.setDSFilter('operator');
    }
    tradeValueChanged(e) {
      this.setState({'critTrade': e.value});
      this.setDSFilter('service');
      this.setDSFilter('alliance');
      this.setDSFilter('operator');
      this.setDSFilter('vessel');
  
    }


    render() {
      return (
        <EnquiryBase 
        formItemsSimple={this.formItemsSimple}
        formItemsBasic={this.formItemsBasic}
        searchResultComponent={utilizationChart} 
        firebase={this.props.firebase} />
      );  
    }
  }

  export default Utilization;