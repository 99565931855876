import ODataStore from 'devextreme/data/odata/store';
import DataSource from 'devextreme/data/data_source';
import {oDataStoreDefaultSetting} from '../oDataStoreDefaultSetting';

export const yesNoBoth = [
  { 'Name': 'Yes', 'ID': 'Y'},
  { 'Name': 'No', 'ID': 'N'}
  ];
  
export function DDown_Ship() {
  return new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/DDown_Ship`,
      ...oDataStoreDefaultSetting
    })
  });
}

export function DDown_FuelType() {
  return new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/DDown_FuelType`,
      ...oDataStoreDefaultSetting
    })
  });
}
export  function DDown_Buyer() {
  return new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/DDown_Buyer`,
      ...oDataStoreDefaultSetting
    })
  });
}

export  function DDown_Seller() {
  return new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/DDown_Seller`,
      ...oDataStoreDefaultSetting
    })
  });
}

export  function DDown_SellerScrapped() {
  return new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/DDown_SellerScrapped`,
      ...oDataStoreDefaultSetting
    })
  });
}

export  function DDown_Charterer() {
  return new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/DDown_Charterer`,
      ...oDataStoreDefaultSetting
    })
  });
}

export  function DDown_Flag() {
  return new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/DDown_Flag`,
      ...oDataStoreDefaultSetting
    })
  });
}

export function DDown_Shipyard() {
  return new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/DDown_Shipyard`,
      ...oDataStoreDefaultSetting
    })
  });
}

export function DDown_Operator() {
  return new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/DDown_Operator`,
      ...oDataStoreDefaultSetting
    })
  });
}

export function DDown_SseGroup() {
  return new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/DDown_SseGroup`,
      ...oDataStoreDefaultSetting
    })
  });
}

export const operatorSummaryDataSource = new DataSource({
  store: new ODataStore({
    url: `${process.env.GATSBY_ODATA_SERVER_URL}/OperatorSummary`,
    ...oDataStoreDefaultSetting
  }),
});

export const operatorShipSummaryDataSource = new DataSource({
  store: new ODataStore({
    url: `${process.env.GATSBY_ODATA_SERVER_URL}/OperatorShipSummary`,
    ...oDataStoreDefaultSetting
  }),
});

export const operatorCapacityHistoryDataSource = new DataSource({
  store: new ODataStore({
    url: `${process.env.GATSBY_ODATA_SERVER_URL}/OperatorCapacityHistory`,
    ...oDataStoreDefaultSetting
  }),
});

export const ownerSummaryDataSource = new DataSource({
  store: new ODataStore({
    url: `${process.env.GATSBY_ODATA_SERVER_URL}/OwnerSummary`,
    ...oDataStoreDefaultSetting
  }),
});

  export const orderBookDataSource = new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/FleetList`,
      key: 'vesselId',
      keyType: 'String',
      ...oDataStoreDefaultSetting
    }),

    filter: ['shipStatus','=','O']
  });

  export const formerNamesDataSource = new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/FleetList`,
      key: 'vesselId',
      keyType: 'String',
      ...oDataStoreDefaultSetting
    }),

    select: ['formerNames', 'vesselId']
  });

  export const fleetRecordDataSource = new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/FleetList`,
      key: 'vesselId',
      keyType: 'String',
      ...oDataStoreDefaultSetting
    })
  });

  export const fleetDataSource = new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/FleetList`,
      key: 'vesselId',
      keyType: 'String',
      ...oDataStoreDefaultSetting
    }),

    filter: ['shipStatus','=','xyz']
  });

  export const servicesDataSource = new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/Services`,
      key: 'lsCode',
      keyType: 'String',
      ...oDataStoreDefaultSetting
    }),
  });

  export const svcRecordDataSource = new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/Services`,
      key: 'lsCode',
      keyType: 'String',
      ...oDataStoreDefaultSetting
    }),
  });

  export const svcRecordDataSource1 = new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/Services`,
      key: 'lsCode',
      keyType: 'String',
      ...oDataStoreDefaultSetting,
      onLoaded: function (result) {
        result.forEach(element => {
          element.rotations = JSON.parse(element.rotations).toString();
        }        
        );

      }
    }),
  });

  export function DDown_Alliance() {
    return  new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/DDown_Alliance`,
      ...oDataStoreDefaultSetting
    }),
  });
}

export function dDown_ServiceName() {
    return  new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/Services`,
      key: 'lsCode',
      keyType: 'String',
      ...oDataStoreDefaultSetting
    }),

    select: ['lsCode', 'serviceName', 'serviceNameForSrh'],
    sort: ['sequence']
  });
}

export function dDown_Coverage() {
    return new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/dDown_Coverage`,
      key: 'serviceId',
      keyType: 'Int32',
      ...oDataStoreDefaultSetting
    })
  });
}

export function dDown_Trade() {
  return new DataSource({
  store: new ODataStore({
    url: `${process.env.GATSBY_ODATA_SERVER_URL}/DDown_Trade`,
    key: 'portCode',
    keyType: 'String',
    ...oDataStoreDefaultSetting
  })
});
}

export function dDown_serviceTrade() {
  return new DataSource({
  store: new ODataStore({
    url: `${process.env.GATSBY_ODATA_SERVER_URL}/DDown_serviceTrade`,
    key: 'trade',
    keyType: 'String',
    ...oDataStoreDefaultSetting
  })
});
}

export function dDown_TradeGroup() {
  return new DataSource({
  store: new ODataStore({
    url: `${process.env.GATSBY_ODATA_SERVER_URL}/DDown_TradeGroup`,
    ...oDataStoreDefaultSetting
  })
});
}

export function dDown_Port() {
    return new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/DDown_Port`,
      ...oDataStoreDefaultSetting
    })
  });
}


export function dDown_PortCountry() {
  return new DataSource({
  store: new ODataStore({
    url: `${process.env.GATSBY_ODATA_SERVER_URL}/DDown_PortCountry`,
    ...oDataStoreDefaultSetting
  })
});
}

export function dDown_ThruputPort() {
  return new DataSource({
  store: new ODataStore({
    url: `${process.env.GATSBY_ODATA_SERVER_URL}/DDown_ThruputPort`,
    ...oDataStoreDefaultSetting
  })
});
}

export function dDown_CapacityBatch() {
    return new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/DDown_CapacityBatch`,
      key: 'batchLabel',
      keyType: 'string',
      ...oDataStoreDefaultSetting
    })
  });
}

export function dDown_CapacityDepWeek() {
  return new DataSource({
  store: new ODataStore({
    url: `${process.env.GATSBY_ODATA_SERVER_URL}/DDown_CapacityDepWeek`,
    key: 'depWeek',
    keyType: 'number',
    ...oDataStoreDefaultSetting
  })
});
}

export function dDown_CompanyCountry() {
  return  new DataSource({
  store: new ODataStore({
    url: `${process.env.GATSBY_ODATA_SERVER_URL}/DDown_CompanyCountry`,
    ...oDataStoreDefaultSetting
    })
  });
}

export function DDown_ScrapCountry() {
  return  new DataSource({
  store: new ODataStore({
    url: `${process.env.GATSBY_ODATA_SERVER_URL}/DDown_ScrapCountry`,
    ...oDataStoreDefaultSetting
    })
  });
}

export function dDown_BenOwner() {
  return  new DataSource({
  store: new ODataStore({
    url: `${process.env.GATSBY_ODATA_SERVER_URL}/DDown_BenOwner`,
    ...oDataStoreDefaultSetting
    })
  });
}

  export const port2PortDataSource = new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/Port2Port`,
      ...oDataStoreDefaultSetting
    }),
    filter:[['depPort','=','CNYTN'],'AND',['arrPort','=','USLAX']]
  });

  export const shipPortHistoryDataSource = new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/ShipPortHistory`,
      key: 'arr',
      keyType: 'Date',
      ...oDataStoreDefaultSetting
    }),

  });

  export function DDown_RegionGroup() {
    return  new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/DDown_RegionGroup`,
      ...oDataStoreDefaultSetting
      })
    });
  }
  
  export const svcVesselChartDataSource = new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/ServiceVesselChart`,
      ...oDataStoreDefaultSetting
    }),
    filter:['svcId','=','0']
  });

  export const svcVesselDataSource = new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/ServiceVessels`,
      ...oDataStoreDefaultSetting
    }),
    filter:['lsCode','=','0']
  });

  export const svcRotationDataSource = new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/ServiceRotations`,
      ...oDataStoreDefaultSetting
    }),

  });

  export const svcSlotDataSource = new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/ServiceSlots`,
      ...oDataStoreDefaultSetting
    }),

  });

    export function sseGroupDataSource() {
      return new DataSource({
      store: new ODataStore({
        url: `${process.env.GATSBY_ODATA_SERVER_URL}/SseGroups`,
        key: 'idx',
        keyType: 'string',
        ...oDataStoreDefaultSetting
      })
    });
  }
  
  export const fleetFixturesDataSource = new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/FleetFixtures`,
      key: 'id',
      keyType: 'number',
      ...oDataStoreDefaultSetting
    }),
  });

  export const fleetFixturesByShipDataSource = new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/FleetFixturesByShip`,
      key: 'id',
      keyType: 'number',
      ...oDataStoreDefaultSetting
    }),
  });

  export const fleetSalesDataSource = new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/FleetSales`,
      key: 'id',
      keyType: 'number',
      ...oDataStoreDefaultSetting
    }),    
  });

  export const fleetSalesScrappedDataSource = new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/FleetSalesScrapped`,
      key: 'vesselId',
      keyType: 'string',
      ...oDataStoreDefaultSetting
    }),    
  });

  export const OwnerCurrentFleetDataSource = new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/OwnerCurrentFleet`,
      key: 'vesselId',
      keyType: 'String',
      ...oDataStoreDefaultSetting
    }),    
    filter: ['vesselId','=','1']
  });

  export const OwnerOrderbookDataSource = new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/OwnerOrderbook`,
      key: 'vesselId',
      keyType: 'String',
      ...oDataStoreDefaultSetting
    }), 
    filter: ['vesselId','=','1']
  });

  export const OwnerFleetChartDataSource = new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/OwnerFleetChart`,
      ...oDataStoreDefaultSetting
    }),
    filter: ['beneficialOwner','=','Hing'] 
  });

  export const OperatorServiceDataSource = new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/OperatorService`,
      key: 'lsCode',
      keyType: 'String',
      ...oDataStoreDefaultSetting
    }),
  });
  
  
  export const OperatorCurrentFleetDataSource = new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/OperatorCurrentFleet`,
      key: 'vesselId',
      keyType: 'String',
      ...oDataStoreDefaultSetting
    }),    
    filter: ['vesselId','=','1']
  });

  export const OperatorOrderbookDataSource = new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/OperatorOrderbook`,
      key: 'vesselId',
      keyType: 'String',
      ...oDataStoreDefaultSetting
    }),    
    filter: ['vesselId','=','1']
  });

  export const OperatorFleetChartDataSource = new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/OperatorFleetChart`,
      ...oDataStoreDefaultSetting
    }),    
    filter: ['operatorGroup','=','Hing'] 
  });

  export const OrderbookDeliveryDataSource = new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/OrderbookDelivery`,
      ...oDataStoreDefaultSetting
    }),    
  });

  export const OrderbookOrderDataSource = new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/OrderbookOrder`,
      ...oDataStoreDefaultSetting
    }),    
  });

  export const FleetHistoryDataSource = new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/FleetHistory`,
      ...oDataStoreDefaultSetting
    }),    
    filter: ['imoNo','=','0']
  });

  export const ThruputAnnualDataSource = new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/PortThruputAnnual`,
      ...oDataStoreDefaultSetting
    })
  });

  export const ThruputAnnualDataSource1 = new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/PortThruputAnnual`,
      ...oDataStoreDefaultSetting
    })
  });

  export const ThruputYtdChartDataSource = new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/PortThruputYtdChart`,
      ...oDataStoreDefaultSetting
    })
  });

  export const ThruputDataSource = new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/PortThruput`,
      ...oDataStoreDefaultSetting
    })
  });

  export const CongestionBubbleMapByPortGroupSource = new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_PUBLIC_SERVER_URL}/CongestionBubbleMapByPortGroup`,
      
      onLoaded: function (result) {
        result.forEach(element => {
          element.coordinates = [element.longitudeValue, element.latitudeValue];
          element.attributes = {text: element.portGroup,
            value: element.aTeu,
            tooltip: `<div style='font-size:20px; margin-bottom: 25px'><b>${element.portGroup}</b></div>\n \n<div style='font-size:15px'><b>TEU at Anchor:</b> ${element.aTeu.toLocaleString(undefined)}\n<b>TEU at Port:</b> ${element.pTeu?element.pTeu.toLocaleString(undefined):'-'}\n<b>Ships at Anchor:</b> ${element.aShips}\n<b>Ships at Port:</b> ${element.pShips}\n<b>Queue to Berth Ratio:</b> ${element.qBerthRatio?element.qBerthRatio.toFixed(2):'-'}</div>`
          }
        }        
        );

      },          
      ...oDataStoreDefaultSetting
    }),sort: [{selector:'aTeu', desc:true}],
  });

  export const CongestionOverviewDataSource = new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_PUBLIC_SERVER_URL}/CongestionOverview`,
      onLoaded: function (result) {
        result = result.sort((a,b) => {
          if (a.statusCat === 'Ships at anchorage/waiting') return -1;
          if (a.statusCat === 'Ships at port') return -1;
          if (a.statusCat === 'Ships at shipyard') return -1;
          if (a.statusCat === 'Active ships') return -1;
          return 1;
        })
        result.forEach(element => {
          element.chartData = [{cat: element.statusCat, teu:element.ttlTeu}];
        }        
        );

      },            
      ...oDataStoreDefaultSetting
    })
  });

  export function DDown_utilAlliance() {
    return  new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/DDown_utilAlliance`,
      ...oDataStoreDefaultSetting
      })
    });
  }

  export function DDown_utilOperator() {
    return  new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/DDown_utilOperator`,
      ...oDataStoreDefaultSetting
      })
    });
  }

  export function DDown_utilService() {
    return  new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/DDown_utilService`,
      ...oDataStoreDefaultSetting
      })
    });
  }

  export function DDown_utilTrade() {
    return  new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/DDown_utilTrade`,
      ...oDataStoreDefaultSetting
      })
    });
  }

  export function DDown_utilVessel() {
    return  new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_SERVER_URL}/DDown_utilVessel`,
      ...oDataStoreDefaultSetting
      })
    });
  }