import { Box } from '../../../Layout';
import React from 'react';
import { getFirebaseIdToken } from "../../../../utils/auth";
import PivotGrid, { FieldChooser} from 'devextreme-react/pivot-grid';
import Chart, {
  CommonSeriesSettings,
  Series,
  Aggregation,
  Point,
  ArgumentAxis,
  ValueAxis,
  Title,
  Font,
  Legend,
  Label,
  Tooltip,
} from 'devextreme-react/chart';
import ODataStore from 'devextreme/data/odata/store';
import DataSource from 'devextreme/data/data_source';
import {oDataStoreDefaultSettingWithToken} from '../../oDataStoreDefaultSetting';


const customizeTooltip = (pointInfo) => {
  const { aggregationInfo } = pointInfo.point;
  const start = aggregationInfo && aggregationInfo.intervalStart;
  const end = aggregationInfo && aggregationInfo.intervalEnd;
  const handlers = {
    'Average Utilization': (arg) => ({
      text: `${
        !aggregationInfo
          ? `Date: ${arg.argument.toDateString()}`
          : `Interval: ${start.toDateString()} - ${end.toDateString()}`
      }<br/>Utilization: ${arg.value.toFixed(2)} %`,
    }),
    'Utilization range': (arg) => ({
      text: `Interval: ${start.toDateString()} - ${end.toDateString()}<br/>Utilization range: ${
        arg.rangeValue1
      } - ${arg.rangeValue2} %`,
    })
  };
  return handlers[pointInfo.seriesName](pointInfo);
};

class utilizationChart extends React.Component {
    constructor(props) {
      super(props);
      this.setChart = (ref) => { 
        if (ref != null)
          this._chart = ref.instance;
      }; 

      this.dataSource = new DataSource({
        store: new ODataStore({
          url: `${process.env.GATSBY_ODATA_SERVER_URL}/capacityUtilProd`,
          onLoaded: function (result) {
            result.forEach(element => {
              element.utilization = Math.round(((element.utilization * 100) + Number.EPSILON) * 100) /100;
            }        
            );
    
          },
          ...oDataStoreDefaultSettingWithToken(this.props.firebase)
        }),
        paginate:false
      });

      this.dataSource.filter('tradeGroupSeq','=',3);    

    }

    componentDidMount() {
      setTimeout(function(){
        
      })
    }

    load(filter) {
      if (!filter || filter.length <= 0) {
        alert('Please input at least one field.');
        return ;
      }

      getFirebaseIdToken(this.props.firebase, () =>  {
        this.dataSource.filter(filter);
        this.dataSource.reload();
      });

    }

    resetResult() {
      this.dataSource.filter('tradeGroupSeq','=',3); 
    }

    calculateRangeArea (aggregationInfo) {
      if (!aggregationInfo.data.length) {
        return null;
      }
      const utilization = aggregationInfo.data.map((item) => item.utilization);
      return {
        aisDepDate: new Date(
          (aggregationInfo.intervalStart.valueOf() + aggregationInfo.intervalEnd.valueOf()) / 2,
        ),
        maxUtilization: Math.max.apply(null, utilization),
        minUtilization: Math.min.apply(null, utilization),
      };
    };

    render() {
      return (
        <React.Fragment>
          <Box m={'0 0 20px 0'}>
          <Chart
            id="chart"
            ref={this.setChart}
            dataSource={this.dataSource}
          >
            <CommonSeriesSettings argumentField="aisDepDate" />
            <Series
              axis="utilization"
              color="#ffc0bb"
              type="rangearea"
              rangeValue1Field="minUtilization"
              rangeValue2Field="maxUtilization"
              name="Utilization range"
            >
              <Aggregation
                enabled={true}
                calculate={this.calculateRangeArea}
                method="custom"
              />
            </Series>
            <Series
              axis="utilization"
              color="#e91e63"
              valueField="utilization"
              name="Average Utilization"
            >
              <Point size={7} />
              <Aggregation
                enabled={true}
                method={'avg'}
              />
            </Series>

            <ArgumentAxis
              aggregationInterval={{ weeks: 1 }}
              valueMarginsEnabled={false}
              argumentType="datetime"
            />
            <ValueAxis name="utilization">
              <Title text="Utilization %(Avg, Max, Min by Week)">
                <Font color="#e91e63" />
              </Title>
              <Label>
                <Font color="#e91e63" />
              </Label>
            </ValueAxis>
            <Legend visible={false} />
            <Tooltip
              enabled={true}
              customizeTooltip={customizeTooltip}
            />
            
          </Chart>
          </Box>
          
          <text><br></br></text>
              
        </React.Fragment>
      );
    }
  }

  export default utilizationChart;